import * as React from "react";
import Layout from "../../components/Layout";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import GeneralTab from "../../components/HTabs";
import { Seo } from "../../components/seo";
import Document from "../../components/Document";
import ImgContent from "../../components/GSTTemp/ImgContent";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function SecretarialAudit() {
  /* Slider */
  // const SecretarialAuditSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Secretarial Audit`,
  //     content: `Advisory Service for Secretarial Audit`,
  //     image: "/imgs/registration/dsc/dsc-doc.png",
  //     alt_tag: "Best Online Advisory Service for Secretarial Audit in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 10,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Advisory Service for Secretarial Audit`,
  //     content: `Best Advisory Service for Secretarial Audit Platform`,
  //     image: "/imgs/registration/iec/iec-reg.png",
  //     alt_tag:
  //       "Best Online Advisory Service for Secretarial Audit  near Bangalore",
  //   },

  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Advisory Service for Secretarial Audit in Hosur`,
  //     content: `One the top most success rated Advisory Service for Secretarial Audit  in Hosur`,
  //     image: "/imgs/registration/tax/tax-reg.png",
  //     alt_tag: "Best Online Advisory Service for Secretarial Audit in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Secretarial Audit`,
    buyBtnLink: `#pricing-buy`,
    price: `9999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };

  /* About Data Without Form*/
  const SecretarialAuditAboutData = {
    title: `Secretarial Audit`,
    sub_title: `Largest audit compliance business in India`,
    paragraph: `Auditing entails thoroughly inspecting and verifying the company's overall
    operations and functions, data, records, efficiency, and so on. Secretarial
    auditing is the examination of annual conformity with all relevant rules and
    regulations. Aside from regulators and stakeholders, the Secretarial audit
    ensures that management's personnel are in compliance.Enhances the trust of the company's directors,
    creditors, shareholders, employees, and management in its ability to run effectively`,
    header: `Secretarial auditing is compliant with the law`,
    points: [
      `The obedient party benefits from the goodwill.`,
      `Keeps track of and controls any compliance violations.`,
      `Secretarial audit makes sure that everything is done legally.`,
    ],
  };

  /* Img Content Component Data */
  const SecretarialAudiIcData = {
    id: '',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: "Secretarial Audit's Applicability in India",
    image: '/imgs/gifs/compliances.gif',
    alt_tag: "Secretarial Audit's Applicability in India",
    Paragraph: '',
    points: [
      {
        head: '',
        content: `The Secretarial Audit's scope, applicability, procedure, etc. are all
        governed by Section 204(1) of the Companies Act of 2013. As a result,
        the following types of firms must conduct audits:`,
        icon: true,
      },
      {
        head: `Every Unlisted Entity`,
        content: `Unregistered Public Company with Paid-Up Share Capital Exceeding ₹ 50 Crore.`,
        icon: true,
      },
      {
        content: `Non - listed Public Company with a Total Income that exceeds ₹ 250 Crore.`,
        icon: true,
      },
      {
        head: `Subordinate private corporation of an unlisted public company with:`,
        content: `Surpassing RS.50 Crore in paid-up capital.`,
        icon: true,
      },
      {
        content: `Higher than or equal to RS.250 crore in revenue.`,
        icon: true,
      },
    ],
  };
  /* Document Section Data */
  const SecretarialAuditDocData = {
    id: 'sec-aud-doc',
    heading: `Application of Secretarial Audit in various fields`,
    sub_heading: `Secretarial audit, how does it work, and how much of a fine is imposed for
    violating Section 204`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/it/audit.png`,
        alt_tag: `Secretarial Audit`,
        header: `Secretarial Audit`,
        description: `For maintaining compliance is secretarial auditing,
        which supports monitoring, verifying, and validating that a business
        conforms with all relevant laws and regulations.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/it/companies.png`,
        alt_tag: `For Listed companies`,
        header: `For Listed companies`,
        description: `Authorized PCA/PCS should audit these businesses. PCS should reconcile
        the CDSL/NSDL information with the shares held during the process.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/it/government.png`,
        alt_tag: `For Central Public Sector`,
        header: `For Central Public Sector`,
        description: `According to the Ministry of Heavy Industry and Public Enterprise, 
        corporate governance is required for CPS projects.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/it/bank.png`,
        alt_tag: `For Banks`,
        header: `For Banks`,
        description: `As per to RBI, banks should exercise due diligence and obtain
        a certificate from active professionals stating their degree
        of compliance.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/it/pvtcompany.png`,
        alt_tag: `For Private Company`,
        header: `For Private Company`,
        description: `Every year, these companies must undergo an audit. and are required to present
        the audited report to the relevant ROC, which is done by a specialist in the field.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/it/penalty.png`,
        alt_tag: `Penalty`,
        header: `Penalty`,
        description: `If any of these clauses are broken company director, officer, or secretary
        could face a minimum punishment or penalty of Rs. 1 lakh and a maximum fine of Rs. 5 lakhs.`,
      },
    ],
  };
  /* Tabs Data */
  const SecretarialAuditTabsData = {
    id: 'features',
    heading: 'Secretarial Audit Process',
    paragraph: `Although there aren't any strict guidelines for the secretarial
    audit procedure, an auditor should nonetheless follow the processes
    listed below to complete the audit:`,
    body: [
      {
        tab_title: 'Meeting',
        tab_content: [
          {
            content_title: `Step-1`,
            content_paragraph: ` Conference with management to go through the project's
                    parameters and the delivery of their professional fees for
                    carrying out the audit.`,
            points: [],
          },
          {
            content_title: 'Step-2',
            content_paragraph: `Identification of the scope and the actions that will be
                        specified begin once all the primary objects have been
                        set.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/meeting.png',
        alt_img: 'Secretarial Audit Compliances',
      },
      {
        tab_title: 'Agreement',
        tab_content: [
          {
            content_title: `Step-3`,
            content_paragraph: `Following the completion of the PCS, the business shall
                    issue an engagement letter containing all of his
                    appointment's terms and conditions.`,
            points: [],
          },
          {
            content_title: 'Step-4',
            content_paragraph: `The PCS shall plan appropriately for the auditing process,
                        including the initial areas of law to be examined, the
                        dates of auditing visits, the approximate time frame
                        required to complete the auditing job, and so forth.`,
            points: [],
          },
        ],
        content_img: '/imgs/registration/ngo/80g-registration.png',
        alt_img: 'Secretarial Audit Compliances Agreement',
      },
      {
        tab_title: 'Observations',
        tab_content: [
          {
            content_title: `Step-5`,
            content_paragraph: `Conducting the audit and recording all observations and
                    findings made during the auditing procedure.`,
            points: [],
          },
          {
            content_title: 'Step-6',
            content_paragraph: `After the auditing work is finished, PCS will submit the final
                        Audit report in the required format MR-3, duly signed, and
                        stamped with the company secretary in practice.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/observation.png',
        alt_img: 'Best Secretarial Audit Compliances Observation',
      },
      {
        tab_title: 'Penalty',
        tab_content: [
          {
            content_title: `Penalty imposed for violation of Section 204`,
            content_paragraph: `The company, its director, officer, or practicing company
                    secretary if conflicts any provision of this section will be
                    liable with a penalty or fine of a minimum of Rs.1 Lakh and it can
                    be extended to Rs. 5 Lakhs.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/observation.png',
        alt_img: 'Secretarial Audit Compliances Penalty',
      },
    ],
  };
  /* FaQ Data */
  var SecretarialAuditFAQ = [
    {
      header: 'Describe auditing?',
      body: [
        {
          content: ` The scrutiny of financial documents and accounts is not the
          exclusive purpose of an audit. The term "audit" actually refers to
          a thorough examination and verification of a company's overall
          operations, functions, data, records, efficiency, and other
          factors.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'What is a Secretarial Audit?',
      body: [
        {
          content: `Secretarial auditing is a form of legal compliance that supports
          monitoring, examining, and confirming that a corporation operates
          in accordance with the laws and regulations that apply to it.`,
          icon: false,
        },
      ],
    },
    {
      header: `What punishment is imposed for violating Section 204?`,
      body: [
        {
          content: `The company, its director, officer, or practicing company
          secretary if conflicts any provision of this section will be
          liable with a penalty or fine of a minimum of Rs.1 Lakh and it can
          be extended to Rs. 5 Lakhs.`,
          icon: false,
        },
      ],
    },
    {
      header: `When should a secretarial audit be done?`,
      body: [
        {
          content: `The paid-up share capital should be more than ₹50 crores, and the turnover should be more 
          than ₹250 crores. If any of these criteria match, then a secretarial audit becomes compulsory, and a 
          practising Company Secretary is fit to conduct the process of a secretarial audit.`,
          icon: false,
        },
      ],
    },
    {
      header: ` Is there any limitation on the number of Secretarial Audits? `,
      body: [
        {
          content: `Presently, The authority (ICSI) has not placed any limitation on the number of Secretarial 
          audits conducted by a Practicing Company Secretary in a financial year.`,
          icon: false,
        },
      ],
    },
    {
      header: ` Is there any requirement for a peer review of Secretarial Auditors? `,
      body: [
        {
          content: `There is no requisite for peer review of Secretarial Auditors.`,
          icon: false,
        },
      ],
    },
    {
      header: ` How is the Secretarial Auditor appointed? `,
      body: [
        {
          content: `The Secretarial Auditor is required to be appointed through a resolution passed at a duly convened 
          Board meeting. However, it is recommended for Secretarial Auditor to get the letter of engagement from the 
          company.`,
          icon: false,
        },
      ],
    },
    {
      header: `Who signs secretarial audit report?`,
      body: [
        {
          content: `A member of the Institute holding a valid certificate of practice shall be entitled to sign 
          Secretarial Audit Report(s) pursuant to Section 204 of the Companies Act, 2013.`,
          icon: false,
        },
      ],
    },
    {
      header: `Who is applicable for secretarial audit?`,
      body: [
        {
          content: `Every Public Company with Paid-up capital of 50 crores or more, Every Public Company with 
          a turnover of 250 crores or more, Any private company that is a subsidiary of a public company that 
          falls into the above categories shall conduct a Secretarial Audit.`,
          icon: false,
        },
      ],
    },
    {
      header: ` What skills are required for conducting the Secretarial Audit process? `,
      body: [
        {
          content: `Knowledge`,
          icon: true,
        },
        {
          content: `Team`,
          icon: true,
        },
        {
          content: `Documentation and Backup`,
          icon: true,
        },
        {
          content: `Reliance upon management representation`,
          icon: true,
        },
        {
          content: `Complying with the timelines`,
          icon: true,
        },
        {
          content: `Impartial Behaviour`,
          icon: true,
        },
        {
          content: `Maintaining the Audit records`,
          icon: true,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Secretarial Audit'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={SecretarialAuditSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={SecretarialAuditAboutData} />

          <Counter />

          <ImgContent item={SecretarialAudiIcData} />

          <Document docSecData={SecretarialAuditDocData} />

          <GeneralTab HTabsData={SecretarialAuditTabsData} />
          <Cta />
          <FAQAccord items={SecretarialAuditFAQ} />
        </main>
      </Layout>
    </div>
  );
}
